@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flowbite';

@layer base {
  body {
    @apply font-primary text-primary;
    background-image: linear-gradient(to right bottom, #c96fdf, #d780df, #e292df, #eca4e1, #f3b6e4, #f6c1e7, #f8cdea, #fad8ee, #fadff1, #fae5f4, #faecf7, #fbf2f9);
  }
}

@font-face {
  font-family: 'lovelo';
  src: url('./fonts/Lovelo-Black.otf');
  font-weight: normal;
  font-style: normal;
}
.lovelo {
  font-family: 'lovelo';
}

@font-face {
  font-family: 'cursiva';
  src: url('./fonts/Nautilus.otf');
  font-weight: normal;
  font-style: normal;
}
.cursiva {
  font-family: 'cursiva';
}

footer {
  background-color: var(--bg-primary);
  padding: 1rem;
}
main {
  flex: 1; /* Hace que el contenido principal tome el espacio restante */
}
